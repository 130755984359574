@import url('https://cdn.syncfusion.com/ej2/material.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.sidebar {
    box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
    z-index: 10000;
}

@media screen and (max-width:800px) {
    .sidebar {
        z-index: 10000000;
    }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
    z-index: 1000000 !important;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 40px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

/* color-picker style  */

#preview {
    background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
    display: inline-block;
    height: 80px;
    margin: 10px 0;
    min-width: 300px;
    max-width: 600px;
    background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    border: none;
}



.app {
    background-color: #252d4a;
    width: 750px;
    min-height: 200px;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-evenly;

}

.score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
    width: 100%;
    position: relative;
}

.question-count {
    margin-bottom: 20px;
}

.question-count span {
    font-size: 28px;
}

.question-text {
    margin-bottom: 12px;
}

.timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

quiz-button {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.correct {
    background-color: #2f922f;
}

.incorrect {
    background-color: #ff3333;
}

quiz-button:hover {
    background-color: #555e7d;
}

quiz-button:focus {
    outline: none;
}

quiz-button svg {
    margin-right: 5px;
}

/* chat app style */
/* CHAT STYLES */
/* * {
    font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    letter-spacing: 0.5px;
}

*/
.ce-chat-list {
    background-color: rgb(240, 240, 240) !important;
}

.ce-chats-container {
    background-color: rgb(240, 240, 240) !important;
}

.ce-active-chat-card {
    background-color: #cabcdc !important;
    border: 4px solid #cabcdc !important;
    border-radius: 0px !important;
}

.ce-chat-subtitle-text {
    color: #595959 !important;
}

.ce-chat-form-container {
    padding-bottom: 20px !important;
}

.ce-text-input {
    border-radius: 6px !important;
    border: 1px solid #3b2a50 !important;
}

.ce-primary-button {
    border-radius: 6px !important;
    background-color: #7554a0 !important;
    position: relative;
    bottom: 1px;
}

.ce-danger-button {
    background-color: white !important;
    border-radius: 22px !important;
}

.ce-settings {
    background-color: rgb(240, 240, 240) !important;
}

.ce-autocomplete-input {
    border-radius: 6px !important;
    border: 1px solid #3b2a50 !important;
}

.ce-autocomplete-options {
    border-radius: 6px !important;
    border: 1px solid #3b2a50 !important;
    background-color: white !important;
}

.ce-chat-settings-container {
    padding-top: 12px !important;
}

.ce-chat-avatars-row {
    display: none !important;
}

/* CUSTOM FEED */

.chat-feed {
    height: 100%;
    width: 100%;
    overflow: scroll;
    background-color: rgb(240, 240, 240);
}

.chat-title-container {
    width: calc(100% - 36px);
    padding: 18px;
    text-align: center;
}

.chat-title {
    color: #7554a0;
    font-weight: 800;
    font-size: 24px;
}

.chat-subtitle {
    color: #7554a0;
    font-weight: 600;
    font-size: 12px;
    padding-top: 4px;
}

.message-row {
    float: left;
    width: 100%;
    display: flex;
    margin-left: 18px;
}

.message-block {
    width: 100%;
    display: inline-block;
}

.message-avatar {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    color: white;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 48px;
}

.message {
    padding: 12px;
    font-size: 16px;
    border-radius: 6px;
    max-width: 60%;
}

.message-image {
    margin-right: 18px;
    object-fit: cover;
    border-radius: 6px;
    height: 30vw;
    /* width: 30vw; */
    max-height: 200px;
    max-width: 200px;
    min-height: 100px;
    min-width: 100px;
}

.read-receipts {
    position: relative;
    bottom: 6px;
}

.read-receipt {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    margin: 1.5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
}

.message-form-container {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 36px);
    padding: 18px;
    background-color: rgb(240, 240, 240);
}

.message-form {
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #3b2a50;
    background-color: white;
}

.message-input {
    height: 40px;
    width: calc(100% - 132px);
    background-color: white;
    border: 1px solid white;
    padding: 0px 18px;
    outline: none;
    font-size: 15px;
}

.image-button {
    cursor: pointer;
    padding: 0px 12px;
    height: 100%;
}

.send-button {
    height: 42px;
    background-color: white;
    border: 1px solid white;
    padding: 0px 18px;
    cursor: pointer;
}

.send-icon {
    top: 1px;
    position: relative;
    transform: rotate(-90deg);
}

.picture-icon {
    top: 1px;
    position: relative;
    font-size: 14px;
}

/* FORM STYLES */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62, 5%;
}

.wrapper {
    height: 100vh;
    width: 100%;
    background: rgb(117, 84, 160);
    background: linear-gradient(90deg, rgba(117, 84, 160, 1) 7%, rgba(117, 84, 160, 1) 17%, rgba(106, 95, 168, 1) 29%, rgba(99, 103, 174, 1) 44%, rgba(87, 116, 184, 1) 66%, rgba(70, 135, 198, 1) 83%, rgba(44, 163, 219, 1) 96%, rgba(22, 188, 237, 1) 100%, rgba(0, 212, 255, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    color: #333;
    font-size: 1.2rem;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    border-radius: 0.2rem;
    background-color: rgb(255, 255, 255);
    border: none;
    width: 90%;
    display: block;
    border-bottom: 0.3rem solid transparent;
    transition: all 0.3s;
    outline: none;
    margin-bottom: 25px;
}

.form {
    width: 400px;
}

.title {
    text-align: center;
    color: white;
    margin-bottom: 30px;
    width: 100%;
    font-size: 2.3em;
    ;
}

.button {
    border-radius: 4px;
    border: none;
    background-color: white;
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    padding: 20px;
    width: 200px;
    transition: all 0.4s;
    cursor: pointer;
    margin: 5px;
    width: 90%;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.button:hover span {
    padding-right: 25px;
}

.button:hover span:after {
    opacity: 1;
    right: 0;
}

/* .sidebar {
    box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
    z-index: 10000;
}

@media screen and (max-width:800px) {
    .sidebar {
        z-index: 10000000;
    }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
    z-index: 1000000 !important;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 40px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

/* color-picker style  */
/* 
#preview {
    background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
    display: inline-block;
    height: 80px;
    margin: 10px 0;
    min-width: 300px;
    max-width: 600px;
    background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    border: none;
}



.app {
    background-color: #252d4a;
    width: 750px;
    min-height: 200px;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-evenly;

}

.score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
}

*/
/* QUESTION/TIMER/LEFT SECTION */
/* .question-section {
    width: 100%;
    position: relative;
}

.question-count {
    margin-bottom: 20px;
}

.question-count span {
    font-size: 28px;
}

.question-text {
    margin-bottom: 12px;
}

.timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
}

*/
/* //ANSWERS/RIGHT SECTION
.answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
button {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
}

.correct {
    background-color: #2f922f;
}

.incorrect {
    background-color: #ff3333;
}

button:hover {
    background-color: #555e7d;
}

button:focus {
    outline: none;
}

button svg {
    margin-right: 5px;
}

*/
/* chat app style */
/* CHAT STYLES
* {
    font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    letter-spacing: 0.5px;
}

.ce-chat-list {
    background-color: rgb(240, 240, 240) !important;
}

.ce-chats-container {
    background-color: rgb(240, 240, 240) !important;
}

.ce-active-chat-card {
    background-color: #cabcdc !important;
    border: 4px solid #cabcdc !important;
    border-radius: 0px !important;
}

.ce-chat-subtitle-text {
    color: #595959 !important;
}

.ce-chat-form-container {
    padding-bottom: 20px !important;
}

.ce-text-input {
    border-radius: 6px !important;
    border: 1px solid #3b2a50 !important;
}

.ce-primary-button {
    border-radius: 6px !important;
    background-color: #7554a0 !important;
    position: relative;
    bottom: 1px;
}

.ce-danger-button {
    background-color: white !important;
    border-radius: 22px !important;
}

.ce-settings {
    background-color: rgb(240, 240, 240) !important;
}

.ce-autocomplete-input {
    border-radius: 6px !important;
    border: 1px solid #3b2a50 !important;
}

.ce-autocomplete-options {
    border-radius: 6px !important;
    border: 1px solid #3b2a50 !important;
    background-color: white !important;
}

.ce-chat-settings-container {
    padding-top: 12px !important;
}

.ce-chat-avatars-row {
    display: none !important;
}

/* CUSTOM FEED */
/*
.chat-feed {
    height: 100%;
    width: 100%;
    overflow: scroll;
    background-color: rgb(240, 240, 240);
}

.chat-title-container {
    width: calc(100% - 36px);
    padding: 18px;
    text-align: center;
}

.chat-title {
    color: #7554a0;
    font-weight: 800;
    font-size: 24px;
}

.chat-subtitle {
    color: #7554a0;
    font-weight: 600;
    font-size: 12px;
    padding-top: 4px;
}

.message-row {
    float: left;
    width: 100%;
    display: flex;
    margin-left: 18px;
}

.message-block {
    width: 100%;
    display: inline-block;
}

.message-avatar {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    color: white;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 48px;
}

.message {
    padding: 12px;
    font-size: 16px;
    border-radius: 6px;
    max-width: 60%;
}

.message-image {
    margin-right: 18px;
    object-fit: cover;
    border-radius: 6px;
    height: 30vw;

    max-height: 200px;
    max-width: 200px;
    min-height: 100px;
    min-width: 100px;
}

.read-receipts {
    position: relative;
    bottom: 6px;
}

.read-receipt {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    margin: 1.5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
}

.message-form-container {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 36px);
    padding: 18px;
    background-color: rgb(240, 240, 240);
}

.message-form {
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #3b2a50;
    background-color: white;
}

.message-input {
    height: 40px;
    width: calc(100% - 132px);
    background-color: white;
    border: 1px solid white;
    padding: 0px 18px;
    outline: none;
    font-size: 15px;
}

.image-button {
    cursor: pointer;
    padding: 0px 12px;
    height: 100%;
}

.send-button {
    height: 42px;
    background-color: white;
    border: 1px solid white;
    padding: 0px 18px;
    cursor: pointer;
}

.send-icon {
    top: 1px;
    position: relative;
    transform: rotate(-90deg);
}

.picture-icon {
    top: 1px;
    position: relative;
    font-size: 14px;
}

*/
/* FORM STYLES
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62, 5%;
}

.wrapper {
    height: 100vh;
    width: 100%;
    background: rgb(117, 84, 160);
    background: linear-gradient(90deg, rgba(117, 84, 160, 1) 7%, rgba(117, 84, 160, 1) 17%, rgba(106, 95, 168, 1) 29%, rgba(99, 103, 174, 1) 44%, rgba(87, 116, 184, 1) 66%, rgba(70, 135, 198, 1) 83%, rgba(44, 163, 219, 1) 96%, rgba(22, 188, 237, 1) 100%, rgba(0, 212, 255, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    color: #333;
    font-size: 1.2rem;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    border-radius: 0.2rem;
    background-color: rgb(255, 255, 255);
    border: none;
    width: 90%;
    display: block;
    border-bottom: 0.3rem solid transparent;
    transition: all 0.3s;
    outline: none;
    margin-bottom: 25px;
}

.form {
    width: 400px;
}

.title {
    text-align: center;
    color: white;
    margin-bottom: 30px;
    width: 100%;
    font-size: 2.3em;
    ;
}

.button {
    border-radius: 4px;
    border: none;
    background-color: white;
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    padding: 20px;
    width: 200px;
    transition: all 0.4s;
    cursor: pointer;
    margin: 5px;
    width: 90%;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
        top: 0;
    right: -20px;
    transition: 0.5s;
}

.button:hover span {
    padding-right: 25px;
}

.button:hover span:after {
    opacity: 1;
    right: 0;
}

*/
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    height: 100vh;
}

a {
    text-decoration: none;
    color: #000;
}

p {
    line-height: 1.7;
}

ul {
    list-style: none;
}

li {
    line-height: 2.2;
}

h1,
h2,
h3 {
    font-weight: 600;
    margin-bottom: 10px;
}

.container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 60px;
}

.header ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header ul li {
    margin-left: 20px;
}

.header ul li a {
    display: flex;
    align-items: center;
}

.header ul li a:hover {
    color: #777;
}

.header ul li a svg {
    margin-right: 5px;
}

.heading {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 50px;
    padding: 0 20px;
}

.heading p {
    color: #828282;
}

.goals {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.goal {
    background-color: #f4f4f4;
    margin: 10px 0;
    padding: 20px 0 10px;
    position: relative;
}

.goal .close {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    border: none;
    background: none;
}

.form,
.content {
    width: 70%;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: inherit;
}

.form-group label {
    text-align: left;
    display: block;
    margin: 0 0 5px 3px;
}

.btn {
    padding: 10px 20px;
    border: 1px solid #000;
    border-radius: 5px;
    background: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    appearance: button;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn svg {
    margin-right: 8px;
}

.btn-reverse {
    background: #fff;
    color: #000;
}

.btn-block {
    width: 100%;
    margin-bottom: 20px;
}

.btn:hover {
    transform: scale(0.98);
}

.loadingSpinnerContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #000 transparent #555 transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 600px) {
    .form {
        width: 90%;
    }
    .heading h1 {
        font-size: 2rem;
    }
    .heading p {
        font-size: 1.5rem;
    }
} */


/* @import './assets/css/bootstrap.min.css';
@import './assets/css/all.min.css';
 @import './assets/css/icofont.min.css';
@import './assets/css/lightcase.css';
@import './assets/css/swiper.min.css'; 
@import './assets/css/style.css';*/
 

/*
 @import './assets/js/jquery.js';
@import './assets/js/fontawesome.min.js';
@import './assets/js/waypoints.min.js';
@import './assets/js/bootstrap.bundle.min.js';
@import './assets/js/swiper.min.js';
@import './assets/js/circularProgressBar.min.js';
@import './assets/js/isotope.pkgd.min.js';
@import './assets/js/lightcase.js';
@import './assets/js/assets/js/functions.js' */


/*
@import './assets/css/bootstrap.min.css';
@import './assets/css/style.css';
 @import './assets/css/fonts/icofont.eot'; */
/*
@import './assets/css/lightcase.css';
@import './assets/css/fontawesome.css';
@import './assets/css/fonts/icofont.svg';
@import './assets/css/fonts/icofont.ttf';
@import './assets/css/fonts/icofont.woff';
@import './assets/css/fonts/icofont.woff2'; */

/* 
@import './assets/css/icofont.min.css';
@import './assets/css/style.css.map';

 @import './assets/css/all.min.css'; 
 /*
 @import './assets/css/jquery.rprogessbar.min.css';

@import './assets/css/swiper.min.css';  

@import './assets/css/jCirclize.min.css';
/*@import './assets/sass/style.scss'; */