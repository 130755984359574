.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0.25;
  height: 100vh;
  background-color: #2f3135;
}

.sidebar__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #2f3135;
  color: white;
  border-bottom: 3px solid #26282c;
}

.sidebar__channels {
  flex: 1;
  overflow: scroll;
}

.sidebar__channels::-webkit-scrollbar {
  display: none;
}

.sidebar__addChannel {
  cursor: pointer;
}

.sidebar__addChannel:hover {
  color: white;
}

.sidebar__channelsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #2f3135;
  color: gray;
}

.sidebar__header {
  display: flex;
  align-items: center;
}

.sidebar__voice,
.sidebar__profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: gray;
  background-color: #2f3135;
  padding: 10px;
  border-top: 1px solid gray;
}

.sidebar__voiceInfo {
  flex: 1;
  padding: 10px;
}

.sidebar__voiceInfo > h3 {
  color: #4fb185;
}

.sidebar__voiceInfo > p {
  font-size: smaller;
}

.sidebar__voiceIcon {
  color: #4fb185;
}

.sidebar__voiceIcons > .MuiSvgIcon-root,
.sidebar__profileIcons > .MuiSvgIcon-root {
  padding: 10px;
}

.sidebar__profileInfo {
  flex: 1;
  padding: 10px;
}

.sidebar__profileInfo > h3 {
  color: white;
}

.sidebar__profileAvatar:hover {
  cursor: pointer;
}
